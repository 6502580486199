import React from 'react'
import './loader.scss'
import loader from '../../assets/images/loader.gif'

const Loader = () => {
    return (
        <div class="loading">
            <div class="loader-image">
                <img src={loader} alt="" style={{
                    height: '200px',
                    width: '200px',
                    margin: 'auto',
                    position: 'absolute',
                    top: '0',
                    left: '0',
                    right: '0',
                    bottom: '0',
                }} />
            </div>
        </div>
    )
}

export default Loader
