import React, { useRef, useEffect, useState } from 'react'
import { useForm, Controller } from 'react-hook-form';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { InputTextarea } from 'primereact/inputtextarea';
import { classNames } from 'primereact/utils';
import { Toast } from 'primereact/toast';
import { CreateUnit, GetUnitGridList } from '../services/unit.service'
import { GetUsersList } from '../services/dropdown.service'
import { useSelector } from 'react-redux'
import { useParams } from "react-router-dom";
import Loader from '../components/loader/Loader'

const UserAdd = () => {
    const [loading, setLoading] = useState(false);
    const stateUser = useSelector((state) => state.handleUser);
    const [usersList, setUsersList] = useState([]);
    const toast = useRef(null);

    const { id } = useParams();

    useEffect(() => {
        GetUsersList().then(async (res) => {
            if (res.data.result !== null) {
                setUsersList(res.data.result);
            }
        })
            .catch(function (error) {

            });
        if (id !== null && id !== undefined) {
            GetUnitGridList(id).then(async (res) => {
                if (res.data.result !== null) {
                    setValue("PkUnitid", res.data.result[0].pkUnitid);
                    setValue("UnitName", res.data.result[0].unitName);
                    setValue("UnitLocation", res.data.result[0].unitLocation);
                    setValue("UnitDesc", res.data.result[0].unitDesc);
                    setValue("Unitheadid", res.data.result[0].unitheadid);
                }
            })
                .catch(function (error) {

                });
        }
    }, []);

    const defaultValues = {
        PkUnitid: 0,
        UnitName: "",
        UnitDesc: "",
        Unitheadid: 0,
        UnitLocation: "",
        CreatedBy: stateUser.UserId.toString()
    }
    const { control, formState: { errors }, handleSubmit, reset, setValue } = useForm({ defaultValues });

    const onSubmit = (data) => {
        setLoading(true);
        CreateUnit(data).then(async (res) => {
            if (res.data.result != null) {
                toast.current.show({ severity: 'success', summary: 'Success', detail: 'Unit Created Successfully.!', life: 3000 });
                reset();
            }
            else {
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Error in creating Unit. Please try again later.!', life: 3000 });
            }
            setLoading(false);
        })
            .catch(function (error) {
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Error in creating Unit. Please try again later.!', life: 3000 });
                setLoading(false);
            });
    };

    const getFormErrorMessage = (name) => {
        return errors[name] && <small className="p-error">{errors[name].message}</small>
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)} className="p-fluid grid formgrid">
            {loading ? <Loader /> : null}
            <div className="field col-4">
                <span className="p-float-label">
                    <Controller name="UnitName" control={control} rules={{ required: 'Unit Name is required.' }} render={({ field, fieldState }) => (
                        <InputText value={field.UnitName} {...field} autoFocus className={classNames({ 'p-invalid': fieldState.invalid })} />
                    )} />
                    <label htmlFor="UnitName" className={classNames({ 'p-error': errors.UnitName })}>Unit Name</label>
                </span>
                {getFormErrorMessage('UnitName')}
            </div>
            <div className="field col-4">
                <span className="p-float-label">
                    <Controller name="UnitLocation" control={control} rules={{ required: 'Unit Location is required.' }} render={({ field, fieldState }) => (
                        <InputText value={field.UnitLocation} {...field} className={classNames({ 'p-invalid': fieldState.invalid })} />
                    )} />
                    <label htmlFor="UnitLocation" className={classNames({ 'p-error': errors.UnitLocation })}>Unit Location</label>
                </span>
                {getFormErrorMessage('UnitLocation')}
            </div>
            <div className="field col-4">
                <Controller name="Unitheadid" control={control} render={({ field, fieldState }) => (
                    <Dropdown id="Unitheadid" value={field.value} onChange={(e) => field.onChange(e.value)} options={usersList}
                        placeholder="Select Unit Head" />
                )} />
                {getFormErrorMessage('UnitLocation')}
            </div>
            <div className="field col-12">
                <span className="p-float-label">
                    <Controller name="UnitDesc" control={control} render={({ field }) => (
                        <InputTextarea value={field.UnitDesc} rows={3} />
                    )} />
                    <label htmlFor="UnitDesc" className={classNames({ 'p-error': errors.UnitDesc })}>Description</label>
                </span>
            </div>
            <div className="md:col-12">
                <div className="md:col-2">
                    <Button type="submit" label="Submit" className="mt-2 md:col-2" />
                </div>
            </div>
            <Toast ref={toast} />
        </form>
    )
}

export default UserAdd