import axios from 'axios'

export const CreateMachine = (machineObj) => {
    return axios.post(`${process.env.REACT_APP_API_URL}/Machine`, {
        PkMachineid: machineObj.PkMachineid,
        MachineCode: machineObj.MachineCode,
        MachineName: machineObj.MachineName,
        AssetCode: machineObj.AssetCode,
        PkDepartmentid: machineObj.PkDepartmentid,
        MachineDescription: machineObj.MachineDescription,
        CreatedBy: machineObj.CreatedBy,
        Activities: machineObj.Activities
    });
}

export const GetMachines = () => {
    return axios.get(`${process.env.REACT_APP_API_URL}/Machine`);
}

export const GetMachineDetails = (pk_machineid) => {
    return axios.get(`${process.env.REACT_APP_API_URL}/Machine/${pk_machineid}`);
}

export const GetMachinesGridList = (pk_machineid) => {
    return axios.get(`${process.env.REACT_APP_API_URL}/Machine/Grid/${pk_machineid}`);
}