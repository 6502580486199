import React, { useState, useRef, useEffect } from 'react'
import { useForm, Controller } from 'react-hook-form';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { classNames } from 'primereact/utils';
import { Dropdown } from 'primereact/dropdown';
import { InputTextarea } from 'primereact/inputtextarea';
import { Toast } from 'primereact/toast';
import { GetMachinesList } from '../services/dropdown.service'
import { GetMachineDetails } from '../services/machine.service'
import { CreateDailyChecklist } from '../services/daily.checklist.service'
import QrReader from "modern-react-qr-reader";
import { useSelector } from 'react-redux'
import Loader from '../components/loader/Loader'
import { Checkbox } from 'primereact/checkbox';

const DailyChecklistAdd = () => {
    const stateUser = useSelector((state) => state.handleUser);
    const toast = useRef(null);
    const [checklistActivies, setChecklistActivies] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isRecording, setIsRecording] = useState(false);
    const [machinesList, setMachinesList] = useState([]);

    useEffect(() => {
        GetMachinesList().then(async (res) => {
            if (res.data.result !== null) {
                setMachinesList(res.data.result);
            }
        }).catch(function (error) {

        });
    }, []);

    const defaultValues = {
        checklist_date: '',
        pk_machineid: '',
        remarks: '',
    }

    const { control, formState: { errors }, handleSubmit, reset, setValue } = useForm({ defaultValues });

    const onSubmit = (data) => {
        setLoading(true);
        const param = {};

        const Error = checklistActivies.filter(function (item) {
            return item.status === false
        });

        param.PkMachineid = data.pk_machineid;
        param.Remarks = data.remarks;
        param.AcceptedParameter = "";
        param.Parameter = "";
        param.Error = (Error.length > 0 ? true : false);
        param.CreatedBy = stateUser.UserId.toString();
        param.Activities = JSON.stringify(checklistActivies);

        CreateDailyChecklist(param).then(async (res) => {
            if (res.data.result != null) {
                toast.current.show({ severity: 'success', summary: 'Success', detail: 'Checklist Created Successfully and Uploaded for Approval.!', life: 3000 });
                reset();
                setChecklistActivies([]);
            }
            else {
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Error in creating Checklist. Please try again later.!', life: 3000 });
            }
            setLoading(false);
        })
            .catch(function (error) {
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Error in creating Checklist. Please try again later.!', life: 3000 });
                setLoading(false);
            });
    };

    const getFormErrorMessage = (name) => {
        return errors[name] && <small className="p-error">{errors[name].message}</small>
    };

    const handleScan = (data) => {
        try {
            if (data) {
                const jsonObj = JSON.parse(data);
                if (jsonObj.id) {
                    setValue("pk_machineid", parseInt(jsonObj.id));
                    handleMachineIdChange(parseInt(jsonObj.id));
                    setIsRecording(false);
                }
                else {
                    toast.current.show({ severity: 'error', summary: 'Error', detail: 'This machine is not supported for Daily Checklist.', life: 3000 });
                }
            }
        }
        catch (e) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Please scan a valid QR Code from the machine.', life: 3000 });
        }
    };

    const handleOpenScanner = () => {
        setIsRecording(true);
    }

    const handleMachineIdChange = (val) => {
        setLoading(true);
        GetMachineDetails(val).then(async (res) => {
            const activities = [];
            if (res.data.result !== null) {
                const result = res.data.result;
                const machineActivities = JSON.parse(result.activities);
                for (let i = 0; i < machineActivities.length; i++) {
                    const accepted_value = machineActivities[i].param_type === "DropDown" ? machineActivities[i].accepted_value : (machineActivities[i].range_start + ' - ' + machineActivities[i].range_end + ' ' + machineActivities[i].measurement);
                    const param = machineActivities[i].param_type === "DropDown" ? machineActivities[i].accepted_value : machineActivities[i].range_start;
                    activities.push({
                        activity: machineActivities[i].activity,
                        param_type: machineActivities[i].param_type,
                        accepted_value: accepted_value,
                        range_start: machineActivities[i].range_start,
                        range_end: machineActivities[i].range_end,
                        param: param,
                        needValidation: machineActivities[i].needValidation,
                        values: machineActivities[i].values,
                        status: true
                    })
                }
                setChecklistActivies(activities);
            }
            setValue("pk_machineid", parseInt(val));
            setLoading(false);
        }).catch(function (error) {
            setLoading(false);
        });
    }

    const handleError = (err) => {
        if (err.name === "NoVideoInputDevicesError") {
            setIsRecording(false);
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'No camera found.', life: 3000 });
        }
    };

    const handleActivityParamRowChange = (value, key, ind) => {
        const DailyActivities = JSON.parse(JSON.stringify(checklistActivies));
        if (DailyActivities[ind].param_type === 'DropDown') {
            DailyActivities[ind]["status"] = (DailyActivities[ind].accepted_value === value ? true : false);
        }
        else {
            DailyActivities[ind]["status"] = ((parseInt(DailyActivities[ind].range_start) <= parseInt(value) && parseInt(DailyActivities[ind].range_end) >= parseInt(value)) ? true : false);
        }
        DailyActivities[ind][key] = value;
        setChecklistActivies(DailyActivities);
    };

    const handleCapture = async (target, ind) => {
        if (target.files) {
            if (target.files.length !== 0) {
                setLoading(true);
                const DailyActivities = JSON.parse(JSON.stringify(checklistActivies));
                const file = target.files[0];

                const MAX_WIDTH = 320;
                const MAX_HEIGHT = 180;

                const blobURL = URL.createObjectURL(file);
                const img = new Image();
                img.src = blobURL;
                let source = '';
                img.onload = function () {
                    const [newWidth, newHeight] = calculateSize(img, MAX_WIDTH, MAX_HEIGHT);
                    const canvas = document.createElement("canvas");
                    canvas.width = newWidth;
                    canvas.height = newHeight;
                    const ctx = canvas.getContext("2d");
                    ctx.drawImage(img, 0, 0, newWidth, newHeight);
                    canvas.toBlob(
                        (blob) => {
                            // Handle the compressed image.
                            var reader = new FileReader();
                            reader.onload = function () {
                                var dataUrl = reader.result;
                                DailyActivities[ind]["validated_image"] = dataUrl;
                                DailyActivities[ind]["validated_image_name"] = (new Date()).getTime().toString() + '.' + file.name.split('.').pop();
                                setChecklistActivies(DailyActivities);
                                setLoading(false);
                            };
                            reader.onerror = function () {
                                setLoading(false);
                            };
                            reader.readAsDataURL(blob);
                        },
                    );
                };
            }
        }
    };

    function calculateSize(img, maxWidth, maxHeight) {
        let width = img.width;
        let height = img.height;

        // calculate the width and height, constraining the proportions
        if (width > height) {
            if (width > maxWidth) {
                height = Math.round((height * maxWidth) / width);
                width = maxWidth;
            }
        } else {
            if (height > maxHeight) {
                width = Math.round((width * maxHeight) / height);
                height = maxHeight;
            }
        }
        return [width, height];
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)} className="p-fluid grid formgrid">
            {loading ? <Loader /> : null}
            <div className="field col-6">
                <span className="p-float-label">
                    <Controller name="pk_machineid" control={control} rules={{ required: 'Machine is required.' }} render={({ field, fieldState }) => (
                        <Dropdown id={field.name} value={field.value} onChange={(e) => handleMachineIdChange(e.value)} options={machinesList} className={classNames({ 'p-invalid': fieldState.invalid })} />
                    )} />
                    <label htmlFor="pk_machineid">Machine*</label>
                </span>
                {getFormErrorMessage('pk_machineid')}
            </div>
            <div className="field col-6">
                <span className="p-float-label">
                    <Controller name="checklist_date" control={control} render={({ field, fieldState }) => (
                        <InputText value={field.checklist_date} {...field} autoFocus className={classNames({ 'p-invalid': fieldState.invalid })} disabled />
                    )} />
                    <label htmlFor="checklist_date" className={classNames({ 'p-error': errors.checklist_date })}>CheckList Date</label>
                </span>
                {getFormErrorMessage('checklist_date')}
            </div>
            <div className="field col-12">
                <span className="p-float-label">
                    <Controller name="remarks" control={control} render={({ field, fieldState }) => (
                        <InputTextarea value={field.remarks} rows={3} />
                    )} />
                    <label htmlFor="remarks">Remarks</label>
                </span>
            </div>
            <div className="field col-12">
                {isRecording ?
                    <QrReader
                        delay={300}
                        facingMode={"environment"}
                        onError={handleError}
                        onScan={handleScan}
                        style={{ width: '100%' }}
                    /> : null}
            </div>
            <div className="field col-12">
                <div class="card">
                    <div class="p-datatable p-component p-datatable-responsive-scroll p-datatable-gridlines" data-scrollselectors=".p-datatable-wrapper">
                        <div class="p-datatable-wrapper grid">
                            <table class="p-datatable-table" role="table">
                                <thead class="p-datatable-thead">
                                    <tr role="row">
                                        <th class="" role="columnheader">Activity</th>
                                        <th class="" role="columnheader">Accepted Value</th>
                                        <th class="" role="columnheader">Param</th>
                                        <th class="" role="columnheader">Status</th>
                                        <th class="" role="columnheader">Upload</th>
                                    </tr>
                                </thead>
                                <tbody class="p-datatable-tbody">
                                    {
                                        checklistActivies.map(function (item, index) {
                                            const dropValues = item.values.split(',');

                                            return <tr role="row" class="" key={index}>
                                                <td class="" role="cell">
                                                    <b>{item.activity}</b>
                                                </td>
                                                <td class="" role="cell">
                                                    <b>{item.accepted_value}</b>
                                                </td>
                                                {
                                                    item.param_type === "DropDown" ?
                                                        <td class="" role="cell">
                                                            <Dropdown placeholder='Please select one option' id={"txtparam_type" + index}
                                                                name={"txtparam_type" + index} options={dropValues} value={item.param} onChange={(e) => handleActivityParamRowChange(e.value, 'param', index)} />
                                                        </td>
                                                        : <td>
                                                            <InputText id={"txtparam_type" + index} value={item.param} onChange={(e) => handleActivityParamRowChange(e.target.value, 'param', index)} />
                                                        </td>}
                                                <td class="" role="cell">
                                                    <i className={classNames('pi', { 'text-green-500 pi-check-circle': item.status, 'text-red-500 pi-times-circle': !item.status })}></i>
                                                </td>
                                                {item.needValidation ? <td class="" role="cell">
                                                    <input
                                                        accept="image/*"
                                                        id={"upload-file-" + index}
                                                        type="file"
                                                        capture="environment"
                                                        onChange={(e) => handleCapture(e.target, index)}
                                                        style={{ display: 'none' }}
                                                    />
                                                    {item.validated_image_name !== '' ? <span style={{ paddingLeft: '5px', paddingRight: '5px', textDecoration: 'underline', color: 'blue' }}>{item.validated_image_name}</span> : null}
                                                    <label for={"upload-file-" + index}>
                                                        <span className="pi pi-camera p-button-sm p-button-text"></span>
                                                    </label>
                                                </td> : <td></td>}
                                            </tr>
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div className="field md:col-2">
                <Button type="button" label="Scan" className="mt-2 md:col-2" onClick={handleOpenScanner} />
            </div>
            <div className="field md:col-2">
                <Button type="submit" label="Submit" className="mt-2 md:col-2" />
            </div>
            <Toast ref={toast} />
        </form>
    )
}

export default DailyChecklistAdd