import axios from 'axios'

export const GetUsersList = () => {
    return axios.get(`${process.env.REACT_APP_API_URL}/User/GetDropdownUserList`);
}

export const GetUnitsList = () => {
    return axios.get(`${process.env.REACT_APP_API_URL}/Unit/GetDropdownUnitList`);
}

export const GetDepartmentList = () => {
    return axios.get(`${process.env.REACT_APP_API_URL}/Department/GetDropdownDepartmentList`);
}

export const GetRoleList = () => {
    return axios.get(`${process.env.REACT_APP_API_URL}/Roles/GetDropdownRoleList`);
}

export const GetMachinesList = () => {
    return axios.get(`${process.env.REACT_APP_API_URL}/Machine/GetDropdownMachinesList`);
}