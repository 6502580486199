import axios from 'axios'

export const CreateRole = (deptObj) => {
    return axios.post(`${process.env.REACT_APP_API_URL}/Roles`, {
        PkRoleid: deptObj.PkRoleid,
        RoleName: deptObj.RoleName,
        CheckedMenus: deptObj.CheckedMenus,
        CreatedBy: deptObj.CreatedBy
    });
}

export const GetRoleGridList = (pk_roleid) => {
    return axios.get(`${process.env.REACT_APP_API_URL}/Roles/Grid/${pk_roleid}`);
}