import React, { useRef, useEffect, useState } from 'react'
import { useForm, Controller } from 'react-hook-form';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { classNames } from 'primereact/utils';
import { CreateDepartment, GetDepartmentGridList } from '../services/department.service'
import { GetUsersList, GetUnitsList } from '../services/dropdown.service'
import { useSelector } from 'react-redux'
import { Toast } from 'primereact/toast';
import { useParams } from "react-router-dom";
import Loader from '../components/loader/Loader'

const DepartmentAdd = () => {
    const [loading, setLoading] = useState(false);
    const stateUser = useSelector((state) => state.handleUser);
    const [usersList, setUsersList] = useState([]);
    const [unitsList, setUnitsList] = useState([]);
    const toast = useRef(null);

    const { id } = useParams();

    const defaultValues = {
        PkDepartmentid: 0,
        DepartmentName: '',
        DepartmentDesc: '',
        Departmentheadid: 0,
        PkUnitid: 0,
        CreatedBy: stateUser.UserId.toString()
    }

    const { control, formState: { errors }, handleSubmit, reset, setValue } = useForm({ defaultValues });

    useEffect(() => {
        GetUsersList().then(async (res) => {
            if (res.data.result !== null) {
                setUsersList(res.data.result);
            }
        }).catch(function (error) {

        });

        GetUnitsList().then(async (res) => {
            if (res.data.result !== null) {
                setUnitsList(res.data.result);
            }
        }).catch(function (error) {

        });
        if (id !== null && id !== undefined) {
            GetDepartmentGridList(id).then(async (res) => {
                if (res.data.result !== null) {
                    setValue("PkDepartmentid", res.data.result[0].pkDepartmentid);
                    setValue("DepartmentName", res.data.result[0].departmentName);
                    setValue("DepartmentDesc", res.data.result[0].departmentDesc);
                    setValue("Departmentheadid", res.data.result[0].departmentheadid);
                    setValue("PkUnitid", res.data.result[0].pkUnitid);
                }
            })
                .catch(function (error) {

                });
        }
    }, []);

    const onSubmit = (data) => {
        CreateDepartment(data).then(async (res) => {
            setLoading(true);
            if (res.data.result != null) {
                toast.current.show({ severity: 'success', summary: 'Success', detail: 'Department Created Successfully.!', life: 3000 });
                reset();
            }
            else {
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Error in creating Department. Please try again later.!', life: 3000 });
            }
            setLoading(false);
        })
            .catch(function (error) {
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Error in creating Department. Please try again later.!', life: 3000 });
                setLoading(false);
            });
    };

    const getFormErrorMessage = (name) => {
        return errors[name] && <small className="p-error">{errors[name].message}</small>
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)} className="p-fluid grid formgrid">
            {loading ? <Loader /> : null}
            <div className="field col-4">
                <span className="p-float-label">
                    <Controller name="DepartmentName" control={control} rules={{ required: 'Machine Name is required.' }} render={({ field }) => (
                        <InputText value={field.DepartmentName} {...field} />
                    )} />
                    <label htmlFor="DepartmentName" autoFocus className={classNames({ 'p-error': errors.DepartmentName })}>Department Name</label>
                </span>
                {getFormErrorMessage('DepartmentName')}
            </div>
            <div className="field col-4">
                <Controller name="PkUnitid" control={control} render={({ field, fieldState }) => (
                    <Dropdown id="PkUnitid" value={field.value} onChange={(e) => field.onChange(e.value)} options={unitsList} placeholder='Select Unit' className={classNames({ 'p-invalid': fieldState.invalid })} />
                )} />
            </div>
            <div className="field col-4">
                <Controller name="Departmentheadid" control={control} render={({ field, fieldState }) => (
                    <Dropdown id="Departmentheadid" value={field.value} onChange={(e) => field.onChange(e.value)} options={usersList} placeholder='Select Department Head' className={classNames({ 'p-invalid': fieldState.invalid })} />
                )} />
            </div>
            <div className="field col-12">
                <span className="p-float-label">
                    <Controller name="DepartmentDesc" control={control} render={({ field }) => (
                        <InputTextarea value={field.DepartmentDesc} rows={3} />
                    )} />
                    <label htmlFor="DepartmentDesc" className={classNames({ 'p-error': errors.DepartmentDesc })}>Description</label>
                </span>
            </div>
            <div className="md:col-12">
                <div className="md:col-2">
                    <Button type="submit" label="Submit" className="mt-2 md:col-2" />
                </div>
            </div>
            <Toast ref={toast} />
        </form>
    )
}

export default DepartmentAdd