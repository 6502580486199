import React, { useEffect, useState, useRef } from 'react'
import { useForm, Controller } from 'react-hook-form';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';
import { classNames } from 'primereact/utils';
import { Checkbox } from 'primereact/checkbox';
import { CreateMachine, GetMachinesGridList } from '../services/machine.service'
import { useParams } from "react-router-dom";
import { Toast } from 'primereact/toast';
import { useSelector } from 'react-redux'
import { GetDepartmentList } from '../services/dropdown.service'
import Loader from '../components/loader/Loader'

const MachineAdd = () => {
    const stateUser = useSelector((state) => state.handleUser);
    const [deptsList, setDeptsList] = useState([]);

    const [loading, setLoading] = useState(false);

    const { id } = useParams();
    const toast = useRef(null);

    const [dailyActivities, setDailyActivities] = useState([{
        activity: '',
        param_type: '',
        accepted_value: '',
        values: '',
        range_start: '',
        range_end: '',
        measurement: '',
        needValidation: false
    }]);
    const defaultValues = {
        PkMachineid: 0,
        MachineCode: "",
        MachineName: "",
        AssetCode: "",
        PkDepartmentid: 0,
        MachineDescription: "",
        CreatedBy: stateUser.UserId.toString(),
        Activities: "",
    }

    const { control, formState: { errors }, handleSubmit, reset, setValue } = useForm({ defaultValues });

    const [qrCode, setQrCode] = useState("");

    useEffect(() => {
        GetDepartmentList().then(async (res) => {
            if (res.data.result !== null) {
                setDeptsList(res.data.result);
            }
        }).catch(function (error) {

        });
        if (id !== null && id !== undefined) {
            setLoading(true);
            GetMachinesGridList(id).then(async (res) => {
                if (res.data.result !== null) {
                    setValue("PkMachineid", res.data.result[0].pkMachineid);
                    setValue("MachineCode", res.data.result[0].machineCode);
                    setValue("AssetCode", res.data.result[0].assetCode);
                    setValue("MachineName", res.data.result[0].machineName);
                    setValue("MachineDescription", res.data.result[0].machineDesc);
                    setValue("PkDepartmentid", res.data.result[0].pkDepartmentid);
                    setValue("daily_checklist", res.data.result[0].isDailyCheckist);
                    setValue("preventive_maintenanace", res.data.result[0].isPreventiveMaintanence);
                    setQrCode("data:image/png;base64," + res.data.result[0].qrCode)
                    setDailyActivities(JSON.parse(res.data.result[0].activities));
                }
                setLoading(false);
            })
                .catch(function (error) {
                    setLoading(false);
                });
        }
    }, [id, setValue]);

    const paramTypes = [{
        label: 'DropDown',
        value: 'DropDown',
    }, {
        label: 'Number',
        value: 'Number',
    }]

    const onSubmit = (data) => {
        setLoading(true);
        data.Activities = JSON.stringify(dailyActivities);
        CreateMachine(data).then(async (res) => {
            if (res.data.result != null) {
                toast.current.show({ severity: 'success', summary: 'Success', detail: 'Machine Created Successfully.!', life: 3000 });
                reset();
                setDailyActivities([{
                    activity: '',
                    param_type: '',
                    accepted_value: '',
                    values: '',
                    range_start: '',
                    range_end: '',
                    measurement: '',
                    needValidation: false
                }]);
                setQrCode('');
            }
            else {
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Error in creating Machine. Please try again later.!', life: 3000 });
            }
            setLoading(false);
        })
            .catch(function (error) {
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Error in creating Machine. Please try again later.!', life: 3000 });
                setLoading(false);
            });
    };

    const handleAddNewActivity = () => {
        const DailyActivities = JSON.parse(JSON.stringify(dailyActivities));
        DailyActivities.push({
            activity: '',
            param_type: '',
            accepted_value: '',
            values: '',
            range_start: '',
            range_end: '',
            measurement: '',
            needValidation: false
        });
        setDailyActivities(DailyActivities);
    }

    const handleActivityRowChange = (value, key, ind) => {
        const DailyActivities = JSON.parse(JSON.stringify(dailyActivities));
        DailyActivities[ind][key] = value;
        setDailyActivities(DailyActivities);
    };

    const getFormErrorMessage = (name) => {
        return errors[name] && <small className="p-error">{errors[name].message}</small>
    };

    const onClickDelete = (ind) => {
        const dailyActivitiesObj = JSON.parse(JSON.stringify(dailyActivities));
        dailyActivitiesObj.splice(ind, 1);
        setDailyActivities(dailyActivitiesObj);
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)} className="p-fluid grid formgrid">
            {loading ? <Loader /> : null}
            <div className="field col-6">
                <span className="p-float-label">
                    <Controller name="MachineCode" control={control} render={({ field }) => (
                        <InputText value={field.MachineCode} {...field} disabled />
                    )} />
                    <label htmlFor="MachineCode" className={classNames({ 'p-error': errors.MachineCode })}>Machine Code</label>
                </span>
            </div>
            <div className="field col-6">
                <span className="p-float-label">
                    <Controller name="MachineName" control={control} rules={{ required: 'Machine Name is required.' }} render={({ field, fieldState }) => (
                        <InputText value={field.MachineName} {...field} autoFocus className={classNames({ 'p-invalid': fieldState.invalid })} />
                    )} />
                    <label htmlFor="MachineName" className={classNames({ 'p-error': errors.MachineName })}>Machine Name*</label>
                </span>
                {getFormErrorMessage('MachineName')}
            </div>
            <div className="field col-6">
                <span className="p-float-label">
                    <Controller name="AssetCode" control={control} rules={{ required: 'Asset Code is required.' }} render={({ field, fieldState }) => (
                        <InputText value={field.AssetCode} {...field} className={classNames({ 'p-invalid': fieldState.invalid })} />
                    )} />
                    <label htmlFor="AssetCode" className={classNames({ 'p-error': errors.AssetCode })}>Asset Code*</label>
                </span>
                {getFormErrorMessage('AssetCode')}
            </div>
            <div className="field col-6">
                <Controller name="PkDepartmentid" control={control} render={({ field, fieldState }) => (
                    <Dropdown placeholder='Please select department' id="PkDepartmentid" value={field.value} onChange={(e) => field.onChange(e.value)}
                        options={deptsList} className={classNames({ 'p-invalid': fieldState.invalid })} />
                )} />
            </div>
            <div className="field col-12">
                <span className="p-float-label">
                    <Controller name="MachineDescription" control={control} render={({ field, fieldState }) => (
                        <InputTextarea value={field.MachineDescription} rows={3} />
                    )} />
                    <label htmlFor="MachineDescription">Machine Description</label>
                </span>
            </div>
            <div className="field col-12">
                <div className="flex align-items-center">
                    <Controller name="isOnOff" control={control} render={({ field, fieldState }) => (
                        <>
                            <Checkbox inputId={field.name} checked={field.value} inputRef={field.ref} className={classNames({ 'p-invalid': fieldState.error })} onChange={(e) => field.onChange(e.checked)} />
                            <label htmlFor={field.name} className={classNames({ 'p-error': errors.checked })} style={{ paddingLeft: '10px' }}>Does have On/Off Validation</label>
                        </>
                    )} />
                </div>
            </div>
            <div className="field col-12">
                <div class="card">
                    <div class="p-datatable p-component p-datatable-responsive-scroll p-datatable-gridlines" data-scrollselectors=".p-datatable-wrapper">
                        <div class="p-datatable-header grid">
                            <div className="col-offset-8 col-3 text-right">
                                <span class="text-xl text-900 font-bold">Daily Checklist Activity</span>
                            </div>
                            <div className="col-1">
                                <input type="button" class="p-button" value="Add Row" onClick={handleAddNewActivity} />
                            </div>
                        </div>
                        <div class="p-datatable-wrapper grid" style={{ height: '500px', overflow: 'scroll' }}>
                            <table class="p-datatable-table" role="table" style={{ minWidth: "1500px" }}>
                                <thead class="p-datatable-thead">
                                    <tr role="row">
                                        <th class="" role="columnheader" width="20%">Activity</th>
                                        <th class="" role="columnheader" width="10%">Parameter Type</th>
                                        <th class="" role="columnheader" width="10%">Param Values</th>
                                        <th class="" role="columnheader" width="10%">Accepted Value</th>
                                        <th class="" role="columnheader" width="10%">Accepted Range Start</th>
                                        <th class="" role="columnheader" width="10%">Accepted Range End</th>
                                        <th class="" role="columnheader" width="10%">Measurement</th>
                                        <th class="" role="columnheader" width="10%">Need validation</th>
                                        <th class="" role="columnheader" width="10%">Delete</th>
                                    </tr>
                                </thead>
                                <tbody class="p-datatable-tbody">
                                    {
                                        dailyActivities.map((item, index) => (
                                            <tr role="row" class="" key={index}>
                                                <td class="" role="cell">
                                                    <InputText id={"txtActivity" + index} name={"txtActivity" + index} value={item.activity} onChange={(e) => handleActivityRowChange(e.target.value, 'activity', index)} />
                                                </td>
                                                <td class="" role="cell">
                                                    <Dropdown id={"txtparam_type" + index} name={"txtparam_type" + index} value={item.param_type} onChange={(e) => handleActivityRowChange(e.value, 'param_type', index)} options={paramTypes} />
                                                </td>
                                                {item.param_type === "DropDown" ? <td class="" role="cell">
                                                    <InputText id={"txtvalues" + index} name={"txtvalues" + index} value={item.values} onChange={(e) => handleActivityRowChange(e.target.value, 'values', index)} />
                                                </td> : <td></td>}
                                                {item.param_type === "DropDown" ? <td class="" role="cell">
                                                    <InputText id={"txtAcceptedValue" + index} name={"txtAcceptedValue" + index} value={item.accepted_value} onChange={(e) => handleActivityRowChange(e.target.value, 'accepted_value', index)} />
                                                </td> : <td></td>}
                                                {item.param_type === "Number" ? <td class="" role="cell">
                                                    <InputText id={"txtRangeStart" + index} name={"txtRangeStart" + index} value={item.range_start} onChange={(e) => handleActivityRowChange(e.target.value, 'range_start', index)} />
                                                </td> : <td></td>}
                                                {item.param_type === "Number" ? <td class="" role="cell">
                                                    <InputText id={"txtRangeEnd" + index} name={"txtRangeEnd" + index} value={item.range_end} onChange={(e) => handleActivityRowChange(e.target.value, 'range_end', index)} />
                                                </td> : <td></td>}
                                                {item.param_type === "Number" ? <td class="" role="cell">
                                                    <InputText id={"txtMeasurement" + index} name={"txtMeasurement" + index} value={item.measurement} onChange={(e) => handleActivityRowChange(e.target.value, 'measurement', index)} />
                                                </td> : <td></td>}
                                                <td>
                                                    <Checkbox inputId={"chkNeedValidation" + index} checked={item.needValidation} onChange={(e) => handleActivityRowChange(e.checked, 'needValidation', index)} />
                                                </td>
                                                <td>
                                                    <Button type="button" icon={'pi pi-times'} className="p-button-sm p-button-text" onClick={() => onClickDelete(index)} />
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="field col-12">
                <div className="field col-6">
                    <div className="flex align-items-center">
                        <Controller name="daily_checklist" control={control} render={({ field, fieldState }) => (
                            <>
                                <Checkbox inputId={field.name} checked={field.value} inputRef={field.ref} className={classNames({ 'p-invalid': fieldState.error })} onChange={(e) => field.onChange(e.checked)} />
                                <label htmlFor={field.name} className="ml-2">Daily Checklist</label>
                            </>
                        )} />
                    </div>
                </div>
            </div>
            <div className="field grid col-12">
                <div className="field col-6">
                    <div className="flex align-items-center">
                        <Controller name="preventive_maintenanace" control={control} render={({ field, fieldState }) => (
                            <>
                                <Checkbox inputId={field.name} checked={field.value} inputRef={field.ref} className={classNames({ 'p-invalid': fieldState.error })} onChange={(e) => field.onChange(e.checked)} />
                                <label htmlFor={field.name} className="ml-2">Preventive Maintenance</label>
                            </>
                        )} />
                    </div>
                </div>
                <div className="field col-3">
                    <span className="p-float-label">
                        <Controller
                            name="preventive_maintenanace_date"
                            control={control}
                            rules={{ required: 'Date is required.' }}
                            render={({ field, fieldState }) => (
                                <>
                                    <Calendar inputId={field.name} value={field.value} onChange={field.onChange} dateFormat="dd/mm/yy" className={classNames({ 'p-invalid': fieldState.error })} />
                                    <label htmlFor={field.name}>Birth Date</label>
                                    {getFormErrorMessage(field.name)}
                                </>
                            )}
                        />
                    </span>
                </div>
            </div> */}
            <div className="field col-6">
                <img src={qrCode} alt="QR Code will be generated once submitted." style={{ height: '250px', width: '250px' }} />
            </div>
            <div className="md:col-12">
                <div className="md:col-2">
                    <Button type="submit" label="Submit" className="mt-2 md:col-2" />
                </div>
            </div>
            <Toast ref={toast} />
        </form>
    )
}

export default MachineAdd