import React, { useState, useRef } from 'react'
import './login-layout.scss'
import { useNavigate } from 'react-router-dom'
import { ValidateUser } from '../services/user.service'
import { ADDUSER } from "../redux/action/user";
import { useDispatch } from "react-redux";
import { Toast } from 'primereact/toast';
import Loader from '../components/loader/Loader'
import sidebarNav from '../configs/sidebarNav'

const LoginLayout = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const toast = useRef(null);

    const [isOpened, setIsOpened] = useState(true);
    const [username, setUsername] = useState('support@hashline.in');
    const [password, setPassword] = useState('admin@123');
    const [loading, setLoading] = useState(false);

    const openModal = () => {
        setIsOpened(true);
    };

    const closeModal = () => {
        setIsOpened(false);
    };

    const handleLogin = () => {
        setLoading(true);
        ValidateUser(username, password).then(async (res) => {
            if (res.data.result !== null && res.data.result !== "") {
                dispatch(ADDUSER(res.data.result));
                toast.current.show({ severity: 'success', summary: 'Success', detail: 'Logged in successfully.!', life: 3000 });
                if (res.data.result.pkRoleId !== 0) {
                    const obj = JSON.parse(res.data.result.checkedMenus);
                    const menus = sidebarNav;
                    let loopBreak = false;
                    console.log('menus - ', menus)
                    for (var key of Object.keys(obj)) {
                        if (obj[key].checked) {
                            if (key.indexOf('-') > -1) {
                                for (var i = 0; i < menus.length; i++) {
                                    if (menus[i].children != undefined) {
                                        const link = menus[i].children.filter(function (item) {
                                            return item.key === key
                                        });
                                        if (link.length > 0) {
                                            navigate(link[0].link);
                                            loopBreak = true;
                                            break;
                                        }
                                    }
                                }
                                if (loopBreak)
                                    break;
                            }
                            else {
                                const link = menus.filter(function (item) {
                                    return item.key === key
                                });
                                if (link.length > 0) {
                                    navigate(link[0].link);
                                    break;
                                }
                            }
                        }
                    }
                }
                else
                    navigate('/app/dashboard');
            }
            else {
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Invalid Username & Password. Please Try Again.', life: 3000 });
            }
            setLoading(false);
        })
            .catch(function (error) {
                setLoading(false);
            });
    }

    const handleEmailChange = (e) => {
        setUsername(e.target.value);
    }

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    }

    return (
        <>
            {loading ? <Loader /> : null}
            <div class="scroll-down">SCROLL DOWN</div>
            <div class="container"></div>
            <div class={"modal " + (isOpened ? "is-open" : "")}>
                <div class="modal-container">
                    <div class="modal-left">
                        <h1 class="modal-title">Welcome!</h1>
                        <p class="modal-desc">Hashline TrackPro Application. Please Login to explore the application.</p>
                        <div class="input-block">
                            <label for="email" class="input-label">Email</label>
                            <input type="email" name="email" id="email" placeholder="Email" value={username} onChange={handleEmailChange} />
                        </div>
                        <div class="input-block">
                            <label for="password" class="input-label">Password</label>
                            <input type="password" name="password" id="password" placeholder="Password" value={password} onChange={handlePasswordChange} />
                        </div>
                        <div class="modal-buttons">
                            <a href="#">Forgot your password?</a>
                            <button class="input-button" onClick={handleLogin}>Login</button>
                        </div>
                        {/* <p class="sign-up">Don't have an account? <a href="#">Sign up now</a></p> */}
                    </div>
                    <div class="modal-right">
                        <img src="https://images.unsplash.com/photo-1512486130939-2c4f79935e4f?ixlib=rb-0.3.5&ixid=eyJhcHBfaWQiOjEyMDd9&s=dfd2ec5a01006fd8c4d7592a381d3776&auto=format&fit=crop&w=1000&q=80" alt="" />
                    </div>
                    <button class="icon-button close-button" onClick={closeModal}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
                            <path d="M 25 3 C 12.86158 3 3 12.86158 3 25 C 3 37.13842 12.86158 47 25 47 C 37.13842 47 47 37.13842 47 25 C 47 12.86158 37.13842 3 25 3 z M 25 5 C 36.05754 5 45 13.94246 45 25 C 45 36.05754 36.05754 45 25 45 C 13.94246 45 5 36.05754 5 25 C 5 13.94246 13.94246 5 25 5 z M 16.990234 15.990234 A 1.0001 1.0001 0 0 0 16.292969 17.707031 L 23.585938 25 L 16.292969 32.292969 A 1.0001 1.0001 0 1 0 17.707031 33.707031 L 25 26.414062 L 32.292969 33.707031 A 1.0001 1.0001 0 1 0 33.707031 32.292969 L 26.414062 25 L 33.707031 17.707031 A 1.0001 1.0001 0 0 0 32.980469 15.990234 A 1.0001 1.0001 0 0 0 32.292969 16.292969 L 25 23.585938 L 17.707031 16.292969 A 1.0001 1.0001 0 0 0 16.990234 15.990234 z"></path>
                        </svg>
                    </button>
                </div>
                <button class="modal-button" onClick={openModal}>Click here to login</button>
            </div>
            <Toast ref={toast} />
        </>
    )
}

export default LoginLayout