import React, { useState, useRef, useEffect } from 'react'
import { useForm, Controller } from 'react-hook-form';
import { InputText } from 'primereact/inputtext';
import { classNames } from 'primereact/utils';
import { Button } from 'primereact/button';
import { CreateRole, GetRoleGridList } from '../services/role.service'
import { Tree } from 'primereact/tree';
import { Toast } from 'primereact/toast';
import { useSelector } from 'react-redux'
import { useParams } from "react-router-dom";
import sidebarNav from '../configs/sidebarNav'
import Loader from '../components/loader/Loader'

const RolesAdd = () => {
    const [loading, setLoading] = useState(false);
    const stateUser = useSelector((state) => state.handleUser);
    const toast = useRef(null);

    const { id } = useParams();

    const defaultValues = {
        PkRoleid: 0,
        RoleName: '',
        CreatedBy: stateUser.UserId.toString()
    }

    useEffect(() => {
        if (id !== null && id !== undefined) {
            GetRoleGridList(id).then(async (res) => {
                if (res.data.result !== null) {
                    setValue("PkRoleid", res.data.result[0].pkRoleid);
                    setValue("RoleName", res.data.result[0].roleName);
                    setSelectedKeys(JSON.parse(res.data.result[0].checkedMenus));
                }
            })
                .catch(function (error) {

                });
        }
    }, []);

    const { control, formState: { errors }, handleSubmit, reset, setValue } = useForm({ defaultValues });
    const [selectedKeys, setSelectedKeys] = useState(null);
    const nodes = sidebarNav;

    const onSelectionChange = (e) => {
        setSelectedKeys(e.value);
    }

    const onSubmit = (data) => {
        setLoading(true);
        data.CheckedMenus = JSON.stringify(selectedKeys);
        CreateRole(data).then(async (res) => {
            if (res.data.result != null) {
                toast.current.show({ severity: 'success', summary: 'Success', detail: 'Role Created Successfully.!', life: 3000 });
                reset();
            }
            else {
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Error in creating Role. Please try again later.!', life: 3000 });
            }
            setLoading(false);
        })
            .catch(function (error) {
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Error in creating Role   . Please try again later.!', life: 3000 });
                setLoading(false);
            });
    };

    const getFormErrorMessage = (name) => {
        return errors[name] && <small className="p-error">{errors[name].message}</small>
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)} className="p-fluid grid formgrid">
            {loading ? <Loader /> : null}
            <div className="field col-6">
                <span className="p-float-label">
                    <Controller name="RoleName" control={control} rules={{ required: 'Role Name is required.' }} render={({ field }) => (
                        <InputText value={field.RoleName} {...field} />
                    )} />
                    <label htmlFor="RoleName" className={classNames({ 'p-error': errors.RoleName })}>Role Name</label>
                </span>
                {getFormErrorMessage('role_name')}
            </div>
            <div className="field col-12">
                <span className="p-float-label">
                    <Tree value={nodes} selectionMode="checkbox" selectionKeys={selectedKeys}
                        onSelectionChange={(e) => onSelectionChange(e)} className="w-full md:w-30rem" />
                </span>
            </div>
            <div className="md:col-12">
                <div className="md:col-2">
                    <Button type="submit" label="Submit" className="mt-2 md:col-2" />
                </div>
            </div>
            <Toast ref={toast} />
        </form>
    )
}

export default RolesAdd