import './assets/libs/boxicons-2.1.1/css/boxicons.min.css'
import './scss/App.scss'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { Provider } from 'react-redux';
import store from './redux/store';

import MainLayout from './layout/MainLayout'
import LoginLayout from './layout/LoginLayout'
import Dashboard from './pages/Dashboard'

import UnitAdd from './pages/UnitAdd'
import UnitList from './pages/UnitList'
import DepartmentAdd from './pages/DepartmentAdd'
import DepartmentList from './pages/DepartmentList'
import RolesAdd from './pages/RolesAdd'
import RolesList from './pages/RolesList'

import MachineList from './pages/MachineList'
import MachineAdd from './pages/MachineAdd'
import UserList from './pages/UserList'
import UserAdd from './pages/UserAdd'
import DailyChecklistAdd from './pages/DailyChecklistAdd'
import ReportList from './pages/ReportList'

function App() {
    return (
        <BrowserRouter>
            <Provider store={store}>
                <Routes>
                    <Route path="/app" element={<MainLayout />}>
                        <Route path="dashboard" element={<Dashboard />} />
                        <Route path="user" element={<UserList />} />
                        <Route path="user/add" element={<UserAdd />} />
                        <Route path="user/:id" element={<UserAdd />} />

                        <Route path="role" element={<RolesList />} />
                        <Route path="role/add" element={<RolesAdd />} />
                        <Route path="role/:id" element={<RolesAdd />} />

                        <Route path="department" element={<DepartmentList />} />
                        <Route path="department/add" element={<DepartmentAdd />} />
                        <Route path="department/:id" element={<DepartmentAdd />} />

                        <Route path="unit" element={<UnitList />} />
                        <Route path="unit/add" element={<UnitAdd />} />
                        <Route path="unit/:id" element={<UnitAdd />} />

                        <Route path="machine" element={<MachineList />} />
                        <Route path="machine/add" element={<MachineAdd />} />
                        <Route path="machine/:id" element={<MachineAdd />} />
                        <Route path="daily/add" element={<DailyChecklistAdd />} />
                        <Route path="report" element={<ReportList />} />
                    </Route>
                    <Route path="/" element={<LoginLayout />}>
                    </Route>
                </Routes>
            </Provider>
        </BrowserRouter>
    )
}

export default App
