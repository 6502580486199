import axios from 'axios'

export const CreateDepartment = (deptObj) => {
    return axios.post(`${process.env.REACT_APP_API_URL}/Department`, {
        PkDepartmentid: deptObj.PkDepartmentid,
        DepartmentName: deptObj.DepartmentName,
        DepartmentDesc: deptObj.DepartmentDesc,
        Departmentheadid: deptObj.Departmentheadid,
        PkDepartmentid: deptObj.PkDepartmentid,
        CreatedDate: deptObj.CreatedDate
    });
}

export const GetDepartmentGridList = (pk_departmentid) => {
    return axios.get(`${process.env.REACT_APP_API_URL}/Department/Grid/${pk_departmentid}`);
}