import React from 'react'
import { useSelector } from 'react-redux'
import './user-info.scss'

const UserInfo = ({ user }) => {
    const stateUser = useSelector((state) => state.handleUser);

    return (
        <div className='user-info'>
            <div className="user-info__img">
                <img src={user.img} alt="" />
            </div>
            <div className="user-info__name">
                <span>{stateUser.FirstName + " " + stateUser.LastName}</span>
            </div>
        </div>
    )
}

export default UserInfo
