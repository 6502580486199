export const ADDUSER = (item) => {
  return {
    type: "ADD_USER",
    payload: item,
  }
}

export const RESETUSER = (item) => {
  return {
    type: "RESET_USER"
  }
}