import React, { useState, useEffect } from 'react'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { useNavigate } from 'react-router-dom'
import 'primeicons/primeicons.css';
import { GetRoleGridList } from '../services/role.service'
import Loader from '../components/loader/Loader'

const RolesList = () => {
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);

    const [roles, setRoles] = useState([]);

    useEffect(() => {
        setLoading(true);
        GetRoleGridList(0).then(async (res) => {
            if (res.data.result !== null) {
                setRoles(res.data.result);
            }
            setLoading(false);
        }).catch(function (error) {
            setLoading(false);
        });
    }, []);

    const handleAddNewRole = () => {
        navigate('/app/role/add');
    }

    const onClickEdit = (rowData) => {
        navigate('/app/role/' + rowData.pkRoleid);
    }

    const editTemplate = (rowData) => {
        return <Button type="button" icon={'pi pi-file-edit'} className="p-button-sm p-button-text" onClick={() => onClickEdit(rowData)} />;
    };

    const deleteTemplate = (rowData) => {
        return <Button type="button" icon={'pi pi-times'} className="p-button-sm p-button-text" onClick={() => onClickEdit(rowData)} />;
    };

    const header = (
        <div className="flex flex-wrap align-items-center justify-content-between gap-2">
            <span className="text-xl text-900 font-bold">Roles</span>
            <Button onClick={handleAddNewRole}>Add New Role</Button>
        </div>
    );
    const footer = `In total there are ${roles ? roles.length : 0} roles.`;

    return (
        <div className="card">
            {loading ? <Loader /> : null}
            <DataTable value={roles} header={header} footer={footer} tableStyle={{ minWidth: '60rem' }} paginator rows={5} showGridlines >
                <Column field="roleName" header="Role Name"></Column>
                <Column style={{ flex: '0 0 4rem' }} body={editTemplate} header="Edit"></Column>
                <Column style={{ flex: '0 0 4rem' }} body={deleteTemplate} header="Delete"></Column>
            </DataTable>
        </div>
    )
}

export default RolesList