import axios from 'axios'

export const CreateDailyChecklist = (deptObj) => {
    return axios.post(`${process.env.REACT_APP_API_URL}/DailyChecklistActivity`, {
        PkMachineid: deptObj.PkMachineid,
        Activities: deptObj.Activities,
        AcceptedParameter: deptObj.AcceptedParameter,
        Parameter: deptObj.Parameter,
        Error: deptObj.Error,
        Remarks: deptObj.Remarks,
        CreatedBy: deptObj.CreatedBy
    });
}

export const GetApprovalList = (pk_userId) => {
    return axios.get(`${process.env.REACT_APP_API_URL}/DailyChecklistApproval`, {
        params: {
            pk_userId: pk_userId
        }
    });
}

export const ApproveDailyChecklist = (checklists, pk_userId) => {
    return axios.post(`${process.env.REACT_APP_API_URL}/DailyChecklistApproval`, {
        checklists: checklists,
        pk_userId: pk_userId
    });
}

export const GetDashboardSummary = (pk_userId) => {
    return axios.get(`${process.env.REACT_APP_API_URL}/DailyChecklistApproval/GetDashboardSummary/${pk_userId}`);
}

export const GetMachineWiseReport = (pk_machineid) => {
    return axios.get(`${process.env.REACT_APP_API_URL}/DailyChecklistActivity/GetMachineWiseReport`, {
        params: {
            pk_machineid: pk_machineid
        }
    });
}

export const GetDailyReport = (report_date) => {
    return axios.get(`${process.env.REACT_APP_API_URL}/DailyChecklistActivity/GetDailyReport`, {
        params: {
            report_date: report_date
        }
    });
}