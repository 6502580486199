import axios from 'axios'

export const ValidateUser = (username, password) => {
    return axios.get(`${process.env.REACT_APP_API_URL}/User/ValidateUser`, {
        params: {
            username: username,
            password: password,
        }
    });
}

export const CreateUser = (userObj) => {
    return axios.post(`${process.env.REACT_APP_API_URL}/User`, {
        PkUserid: userObj.PkUserid,
        FirstName: userObj.FirstName,
        LastName: userObj.LastName,
        Email: userObj.Email,
        Mobilenumber: userObj.Mobilenumber,
        PkDepartmentid: userObj.PkDepartmentid,
        SupervisorId: userObj.SupervisorId,
        PkRoleid: userObj.PkRoleid,
        CreatedBy: userObj.CreatedBy,
    });
}

export const GetUserGridList = (pk_userid) => {
    return axios.get(`${process.env.REACT_APP_API_URL}/User/Grid/${pk_userid}`);
}