const initialStore = {
  UserId: '',
  FirstName: '',
  LastName: '',
  Email: '',
  MobileNumber: '',
  pkDepartmentId: '',
  pkRoleId: '',
  checkedMenus: '',
}

const handleUser = (state = initialStore, action) => {
  const users = action.payload
  switch (action.type) {
    case "ADD_USER":
      return {
        ...state,
        UserId: users.userId,
        FirstName: users.firstName,
        LastName: users.lastName,
        Email: users.email,
        MobileNumber: users.mobileNumber,
        pkDepartmentId: users.pkDepartmentId,
        pkRoleId: users.pkRoleId,
        checkedMenus: users.checkedMenus,
      }
    case "RESET_USER":
      return {
        ...state,
        userId: 0,
        firstName: "",
        lastName: "",
        emailId: "",
        mobNo: "",
        ProductAccess: ""
      }

    default:
      return state;
  }
}

export default handleUser;