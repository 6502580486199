import axios from 'axios'

export const CreateUnit = (unitObj) => {
    return axios.post(`${process.env.REACT_APP_API_URL}/Unit`, {
        PkUnitid: unitObj.PkUnitid,
        UnitName: unitObj.UnitName,
        UnitDesc: unitObj.UnitDesc,
        UnitLocation: unitObj.UnitLocation,
        Unitheadid: unitObj.Unitheadid,
        CreatedBy: unitObj.CreatedBy
    });
}

export const GetUnitGridList = (pk_unitid) => {
    return axios.get(`${process.env.REACT_APP_API_URL}/Unit/Grid/${pk_unitid}`);
}