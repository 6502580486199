import React, { useState, useEffect, useRef } from 'react'
import { Pie } from 'react-chartjs-2'
import Box from '../components/box/Box'
import DashboardWrapper, { DashboardWrapperMain, DashboardWrapperRight } from '../components/dashboard-wrapper/DashboardWrapper'
import Loader from '../components/loader/Loader'
import SummaryBox from '../components/summary-box/SummaryBox'
import { data } from '../constants'
import {
    Chart as ChartJS,
    ArcElement,
    Tooltip,
    Legend
} from 'chart.js'
import OverallList from '../components/overall-list/OverallList'
import { Dialog } from 'primereact/dialog';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { classNames } from 'primereact/utils';
import { Button } from 'primereact/button';
import { GetApprovalList, ApproveDailyChecklist, GetDashboardSummary } from '../services/daily.checklist.service'
import { useSelector } from 'react-redux'
import { ConfirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';

ChartJS.register(ArcElement, Tooltip, Legend);

const Dashboard = () => {    
    const [loading, setLoading] = useState(false);
    const stateUser = useSelector((state) => state.handleUser);
    const [summary, setSummary] = useState([]);

    useEffect(() => {
        setLoading(true);
        GetDashboardSummary(stateUser.UserId).then(async (res) => {
            if (res.data.result !== null) {
                setSummary(res.data.result);
            }
            setLoading(false);
        }).catch(function (error) {
            setLoading(false);
        });
    }, [stateUser.UserId]);

    return (
        <DashboardWrapper>
            {loading ? <Loader /> : null}
            <DashboardWrapperMain>
                <div className="row">
                    <div className="col-12 col-md-12">
                        <div className="row">
                            {
                                summary.map((item, index) => (
                                    <div key={`summary-${index}`} className="col-6 col-md-6 col-sm-12">
                                        <SummaryBox item={item} />
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                    {/* <div className="col-4 hide-md" style={{padding: '0 24px'}}>
                        <SummaryBoxSpecial item={data.revenueSummary} />
                    </div> */}
                </div>
                <div className="row">
                    <div className="col-12">
                        <Box>
                            <PendingApprovals setLoading={setLoading} />
                        </Box>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <Box>
                            <RevenueByMonthsChart />
                        </Box>
                    </div>
                </div>
            </DashboardWrapperMain>
            <DashboardWrapperRight>
                <div className="title mb">Overall</div>
                <div className="mb">
                    <OverallList />
                </div>
            </DashboardWrapperRight>
        </DashboardWrapper>
    )
}

export default Dashboard

const RevenueByMonthsChart = () => {

    const data = {
        labels: ['750 KVA Generator - 2', '750 KVA Generator - 1', '750 KVA Generator - 2'],
        datasets: [
          {
            label: '# of Votes',
            data: [12, 19, 5],
            backgroundColor: [
              'rgba(255, 99, 132, 0.2)',
              'rgba(54, 162, 235, 0.2)',
              'rgba(255, 206, 86, 0.2)'
            ],
            borderColor: [
              'rgba(255, 99, 132, 1)',
              'rgba(54, 162, 235, 1)',
              'rgba(255, 206, 86, 1)'
            ],
            borderWidth: 1,
          },
        ],
      };

    return (
        <>
            <div className="title mb">
                Machines with high error rate
            </div>
            <div style={{ width: '500px', height: '500px' }}>
                <Pie data={data} />
            </div>
        </>
    )
}

const PendingApprovals = ({ setLoading }) => {
    const [expandedRows, setExpandedRows] = useState(null);
    const [selectAll, setSelectAll] = useState(false);
    const [selectedChecklists, setSelectedChecklists] = useState(null);
    const [totalRecords, setTotalRecords] = useState(0);
    const [displayBasic, setDisplayBasic] = useState(false);    
    const stateUser = useSelector((state) => state.handleUser);
    const [approvalList, setApprovalList] = useState([]);
    const [visible, setVisible] = useState(false);
    const toast = useRef(null);

    useEffect(() => {
        setLoading(true);
        GetApprovalList(stateUser.UserId).then(async (res) => {
            if (res.data.result !== null) {
                setApprovalList(res.data.result);
                setTotalRecords(res.data.result.length);
            }
            setLoading(false);
        }).catch(function (error) {
            setLoading(false);
        });
    }, [stateUser.UserId]);

    const errorList = [
        {
            id: 3,
            machine_name: '1250 KVA Generator',
            asset_code: '1-04536',
            remarks: 'Due to some reasons',
            checklist_date: '15-06-2023',
            checklists: [{
                "id": 1,
                "checklist": "Oil Check",
                "isChecked": false
            }, {
                "id": 2,
                "checklist": "Tool Sharpness",
                "isChecked": false
            }, {
                "id": 3,
                "checklist": "Check Thermostat",
                "isChecked": true
            }, {
                "id": 4,
                "checklist": "Fluid Level",
                "isChecked": true
            }]
        }];

    const allowExpansion = (rowData) => {
        const activities = JSON.parse(rowData.activities);
        return activities.length > 0;
    };

    const verifiedBodyTemplate = (rowData) => {
        return <i className={classNames('pi', { 'text-green-500 pi-check-circle': rowData.status, 'text-red-500 pi-times-circle': !rowData.status })}></i>;
    };

    const verificationImageTemplate = (rowData) => {
        return rowData.validated_image_name ? <img style={{ height: '200px', width: '200px' }} src={rowData.validated_image} /> : null;
    };

    const rowExpansionTemplate = (data) => {
        const activities = JSON.parse(data.activities);
        return (
            <div className="p-3">
                <h5>Checklist for {data.machine_name}</h5>
                <DataTable value={activities} showGridlines>
                    <Column field="activity" header="Checks"></Column>
                    <Column field="param" header="Value"></Column>
                    <Column header="Verification Image" bodyClassName="text-center" style={{ minWidth: '8rem' }} body={verificationImageTemplate} />
                    <Column field="isChecked" header="Verified" dataType="boolean" bodyClassName="text-center" style={{ minWidth: '8rem' }} body={verifiedBodyTemplate} />
                </DataTable>
            </div>
        );
    };

    const onSelectionChange = (event) => {
        const value = event.value;

        setSelectedChecklists(value);
        setSelectAll(value.length === totalRecords);
    };

    const onSelectAllChange = (event) => {
        const selectAll = event.checked;

        if (selectAll) {
            setSelectAll(true);
            setSelectedChecklists(approvalList);
        } else {
            setSelectAll(false);
            setSelectedChecklists([]);
        }
    };

    const displayPopUp = () => {
        setDisplayBasic(true);
    }

    const hidePopUp = () => {
        setDisplayBasic(false);
    }

    const approvalDialog = () => {
        setVisible(true);
    }

    const reject = () => {
        setVisible(false);
    }

    const accept = () => {
        ApproveDailyChecklist(JSON.stringify(selectedChecklists), stateUser.UserId).then(async (res) => {
            if (res.data.result !== null) {
                setApprovalList(res.data.result);
                setSelectedChecklists([]);
                setTotalRecords(res.data.result.length);
                setVisible(false);
                toast.current.show({ severity: 'success', summary: 'Success', detail: 'Checklist Approved Successfully.!', life: 3000 });
            }
        }).catch(function (error) {

        });
    }

    const header = () => {
        return (
            <div className="flex justify-content-end">
                <span className="p-input-icon-left">
                    <Button type="submit" label="Approve Checklist" className="mt-2 md:col-12" onClick={approvalDialog} />
                </span>
                <span className="p-input-icon-left pl-1">
                    <Button type="submit" label="Show Errored Machines" className="mt-2 md:col-12" onClick={displayPopUp} />
                </span>
            </div>
        );
    };

    const approvedBodyTemplate = (rowData) => {
        const activities = JSON.parse(rowData.activities);
        return (
            <>
                {
                    activities.map(row =>
                        !row.status ? <div className="p-3">
                            <span style={{float: 'left'}}>{row.activity}</span>
                            <i style={{float: 'right'}} className={classNames('pi', 'text-red-500 pi-times-circle')}></i>
                        </div> : null
                    )}
            </>
        );
    };

    const checkListTemplate = (rowData) => {
        const activities = JSON.parse(rowData.activities);
        return (
            <>
                {
                    activities.map(row =>
                        <div className="p-3">
                            <span style={{ float: 'left' }}>{row.activity}</span>
                            <i style={{ float: 'right' }} className={classNames('pi', { 'text-green-500 pi-check-circle': row.status, 'text-red-500 pi-times-circle': !row.status })}></i>                                
                        </div>
                    )}
            </>
        );
    };

    return (
        <>
            <div className="title mb">
                Pending Approval
            </div>
            <div>
                <DataTable value={approvalList} header={header} expandedRows={expandedRows} rowExpansionTemplate={rowExpansionTemplate}
                    onRowToggle={(e) =>
                        setExpandedRows(e.data)
                    } dataKey="id" showGridlines tableStyle={{ minWidth: '50rem' }}
                    selection={selectedChecklists} onSelectionChange={onSelectionChange} selectAll={selectAll} onSelectAllChange={onSelectAllChange} >
                    <Column expander={allowExpansion} style={{ width: '5rem' }} />
                    <Column field="machine_name" header="Machine Name"></Column>
                    <Column field="remarks" header="Remarks"></Column>
                    <Column field="checklist_date" header="Date"></Column>
                    <Column field="order" header="Approval Order"></Column>
                    <Column header="Errored Checks" dataType="boolean" bodyClassName="text-center" style={{ minWidth: '8rem' }} body={approvedBodyTemplate} />
                    <Column selectionMode="multiple" headerStyle={{ width: '3rem' }} />
                </DataTable>
                <Dialog header="Machines with Errored Checks" maximizable visible={displayBasic} style={{ width: '50vw' }} onHide={() => hidePopUp()}>
                    <DataTable value={errorList} dataKey="id" showGridlines tableStyle={{ minWidth: '50rem' }} >
                        <Column field="machine_name" header="Machine Name"></Column>
                        <Column header="Checks" dataType="boolean" bodyClassName="text-center" style={{ minWidth: '8rem' }} body={checkListTemplate} />
                        <Column field="remarks" header="Remarks"></Column>
                    </DataTable>
                </Dialog>
                <ConfirmDialog visible={visible} onHide={() => setVisible(false)} message="Are you sure you want to proceed?"
                    header="Confirmation" icon="pi pi-exclamation-triangle" accept={accept} reject={reject} />
                <Toast ref={toast} />
            </div>
        </>
    )
}