import React, { useRef, useEffect, useState } from 'react'
import { useForm, Controller } from 'react-hook-form';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { classNames } from 'primereact/utils';
import { CreateUser, GetUserGridList } from '../services/user.service'
import { Toast } from 'primereact/toast';
import { GetUsersList, GetDepartmentList, GetRoleList } from '../services/dropdown.service'
import { useParams } from "react-router-dom";
import Loader from '../components/loader/Loader'

const UserAdd = () => {
    const [loading, setLoading] = useState(false);
    const toast = useRef(null);
    const [usersList, setUsersList] = useState([]);
    const [deptsList, setDeptsList] = useState([]);
    const [roleList, setRoleList] = useState([]);

    const { id } = useParams();
    const defaultValues = {
        PkUserid: 0,
        FirstName: '',
        LastName: '',
        Email: '',
        Mobilenumber: '',
        PkDepartmentid: 0,
        SupervisorId: 0,
        PkRoleid: 0,
        CreatedBy: ''
    }

    const { control, formState: { errors }, handleSubmit, reset, setValue } = useForm({ defaultValues });

    useEffect(() => {
        GetUsersList().then(async (res) => {
            if (res.data.result !== null) {
                setUsersList(res.data.result);
            }
        }).catch(function (error) {

        });

        GetDepartmentList().then(async (res) => {
            if (res.data.result !== null) {
                setDeptsList(res.data.result);
            }
        }).catch(function (error) {

        });

        GetRoleList().then(async (res) => {
            if (res.data.result !== null) {
                setRoleList(res.data.result);
            }
        }).catch(function (error) {

        });
        if (id !== null && id !== undefined) {
            GetUserGridList(id).then(async (res) => {
                if (res.data.result !== null) {
                    setValue("PkUserid", res.data.result[0].pkUserid);
                    setValue("FirstName", res.data.result[0].firstName);
                    setValue("LastName", res.data.result[0].lastName);
                    setValue("Email", res.data.result[0].email);
                    setValue("Password", res.data.result[0].password);
                    setValue("Mobilenumber", res.data.result[0].mobilenumber);
                    setValue("PkDepartmentid", res.data.result[0].pkDepartmentid);
                    setValue("SupervisorId", res.data.result[0].supervisorId);
                    setValue("PkRoleid", res.data.result[0].pkRoleid);
                }
            })
                .catch(function (error) {

                });
        }
    }, []);

    const onSubmit = (data) => {
        setLoading(true);
        CreateUser(data).then(async (res) => {
            if (res.data.result != null) {
                toast.current.show({ severity: 'success', summary: 'Success', detail: 'User Created Successfully.!', life: 3000 });
                reset();
            }
            else {
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Error in creating User. Please try again later.!', life: 3000 });
            }
            setLoading(false);
        })
            .catch(function (error) {
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Error in creating User. Please try again later.!', life: 3000 });
                setLoading(false);
            });
    };

    const getFormErrorMessage = (name) => {
        return errors[name] && <small className="p-error">{errors[name].message}</small>
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)} className="p-fluid grid formgrid">
            {loading ? <Loader /> : null}
            <div className="field col-6">
                <span className="p-float-label">
                    <Controller name="FirstName" control={control} rules={{ required: 'Machine Name is required.' }} render={({ field }) => (
                        <InputText value={field.FirstName} autoFocus {...field} />
                    )} />
                    <label htmlFor="FirstName" className={classNames({ 'p-error': errors.FirstName })}>First Name</label>
                </span>
                {getFormErrorMessage('FirstName')}
            </div>
            <div className="field col-6">
                <span className="p-float-label">
                    <Controller name="LastName" control={control} render={({ field, fieldState }) => (
                        <InputText value={field.LastName} {...field} className={classNames({ 'p-invalid': fieldState.invalid })} />
                    )} />
                    <label htmlFor="LastName" className={classNames({ 'p-error': errors.LastName })}>Last Name</label>
                </span>
            </div>
            <div className="field col-6">
                <span className="p-float-label">
                    <Controller name="Email" control={control} rules={{ required: 'Email is required.' }} render={({ field }) => (
                        <InputText value={field.Email} {...field} />
                    )} />
                    <label htmlFor="Email" className={classNames({ 'p-error': errors.Email })}>Email</label>
                </span>
                {getFormErrorMessage('Email')}
            </div>
            <div className="field col-6">
                <span className="p-float-label">
                    <Controller name="Mobilenumber" control={control} render={({ field, fieldState }) => (
                        <InputText value={field.Mobilenumber} {...field} className={classNames({ 'p-invalid': fieldState.invalid })} />
                    )} />
                    <label htmlFor="Mobilenumber" className={classNames({ 'p-error': errors.Mobilenumber })}>Mobile Number</label>
                </span>
            </div>
            <div className="field col-6">
                <Controller name="PkRoleid" control={control} render={({ field, fieldState }) => (
                    <Dropdown id="PkRoleid" placeholder='Select Role' value={field.value} onChange={(e) => field.onChange(e.value)} options={roleList} className={classNames({ 'p-invalid': fieldState.invalid })} />
                )} />
            </div>
            <div className="field col-6">
                <Controller name="SupervisorId" control={control} render={({ field, fieldState }) => (
                    <Dropdown id="SupervisorId" placeholder='Select Supervisor' value={field.value} onChange={(e) => field.onChange(e.value)} options={usersList} className={classNames({ 'p-invalid': fieldState.invalid })} />
                )} />
            </div>
            <div className="field col-6">
                <Controller name="PkDepartmentid" control={control} render={({ field, fieldState }) => (
                    <Dropdown id="PkDepartmentid" placeholder='Select Department' value={field.value} onChange={(e) => field.onChange(e.value)} options={deptsList} className={classNames({ 'p-invalid': fieldState.invalid })} />
                )} />
            </div>
            <div className="md:col-12">
                <div className="md:col-2">
                    <Button type="submit" label="Submit" className="mt-2 md:col-2" />
                </div>
            </div>
            <Toast ref={toast} />
        </form>
    )
}

export default UserAdd