const sidebarNav = [
    {
        key: '0',
        link: '/app/dashboard',
        section: 'menu',
        icon: <i className='bx bx-printer'></i>,
        label: 'Dashboard',
        data: 'Dashboard'
    },
    {
        key: '1',
        link: '#',
        section: 'collapse',
        icon: <i class='bx bx-chevron-right'></i>,
        label: 'Master',
        data: 'Master',
        children: [
            {
                key: '1-0',
                link: '/app/unit',
                section: 'unit',
                icon: <i className='bx bx-printer'></i>,
                label: 'Unit',
                data: 'Unit'
            },
            {
                key: '1-1',
                link: '/app/department',
                section: 'department',
                icon: <i className='bx bx-printer'></i>,
                label: 'Department',
                data: 'Department'
            },
            {
                key: '1-2',
                link: '/app/role',
                section: 'role',
                icon: <i className='bx bx-printer'></i>,
                label: 'Roles',
                data: 'Roles'
            },
            {
                key: '1-3',
                link: '/app/user',
                section: 'user',
                icon: <i className='bx bx-printer'></i>,
                label: 'Users',
                data: 'Users'
            },
            {
                key: '1-5',
                link: '/app/machine',
                section: 'machine',
                icon: <i className='bx bx-printer'></i>,
                label: 'Machines',
                data: 'Machines'
            }
        ]
    },
    {
        key: '2',
        link: '#',
        section: 'collapse',
        icon: <i class='bx bx-chevron-right'></i>,
        label: 'Checklist',
        data: 'Checklist',
        children: [
            {
                key: '2-0',
                link: '/app/daily/add',
                section: 'menu',
                icon: <i className='bx bx-calendar-check' ></i>,
                label: 'Daily Checklist',
                data: 'Daily Checklist'
            },
            {
                key: '2-1',
                link: '/app/preventive/add',
                section: 'menu',
                icon: <i className='bx bx-calendar-check' ></i>,
                label: 'Preventive Maintenance',
                data: 'Preventive Maintenance'
            },
            {
                key: '2-2',
                link: '/app/amc/add',
                section: 'menu',
                icon: <i className='bx bx-calendar-check' ></i>,
                label: 'AMC',
                data: 'AMC'
            },
            {
                key: '2-3',
                link: '/app/calibration/add',
                section: 'menu',
                icon: <i className='bx bx-calendar-check' ></i>,
                label: 'Calibration',
                data: 'Calibration'
            }
        ]
    },
    {
        key: '3',
        link: '/app/report',
        section: 'reports',
        icon: <i className='bx bx-file'></i>,
        label: 'Report',
        data: 'Report'
    }
]

export default sidebarNav