import React, { useState, useEffect } from 'react'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { classNames } from 'primereact/utils';
import 'primeicons/primeicons.css';
import { useForm, Controller } from 'react-hook-form';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { GetMachinesList } from '../services/dropdown.service'
import { GetMachineWiseReport, GetDailyReport } from '../services/daily.checklist.service'

const ReportList = () => {
    const defaultValues = {
        report_date: '',
        pk_machineid: ''
    }

    const [expandedRows, setExpandedRows] = useState(null);

    const { handleSubmit, control, formState: { errors } } = useForm({ defaultValues });

    const [reportType, setReportType] = useState(null);
    const [chosenReport, setChosenReport] = useState(null);
    const [machinesList, setMachinesList] = useState([]);
    const [machineWiseReport, setMachineWiseReport] = useState([]);
    const [dailyReport, setDailyReport] = useState([]);

    const monthList = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'October', 'November', 'December']
    const reportTypes = ['Daily Report', 'Machine Wise Report'];

    useEffect(() => {
        GetMachinesList().then(async (res) => {
            if (res.data.result !== null) {
                setMachinesList(res.data.result);
            }
        }).catch(function (error) {

        });
    }, []);

    const allowExpansion = (rowData) => {
        const activities = JSON.parse(rowData.activities);
        return activities.length > 0;
    };

    const verifiedBodyTemplate = (rowData) => {
        return <i className={classNames('pi', { 'text-green-500 pi-check-circle': rowData.status, 'text-red-500 pi-times-circle': !rowData.status })}></i>;
    };

    const checkedBodyTemplate = (rowData) => {
        return <i className={classNames('pi', { 'text-green-500 pi-check-circle': !rowData.error, 'text-red-500 pi-times-circle': rowData.error })}></i>;
    };

    const rowExpansionTemplate = (data) => {
        const activities = JSON.parse(data.activities);
        return (
            <div className="p-3">
                <h5>Checklist for {data.machine_Name}</h5>
                <DataTable value={activities} showGridlines>
                    <Column field="activity" header="Checks"></Column>
                    <Column field="accepted_value" header="Accepted Value"></Column>
                    <Column field="param" header="Param"></Column>
                    <Column header="Verified" dataType="boolean" bodyClassName="text-center" style={{ minWidth: '8rem' }} body={verifiedBodyTemplate} />
                </DataTable>
            </div>
        );
    };

    const getFormErrorMessage = (name) => {
        return errors[name] && <small className="p-error">{errors[name].message}</small>
    };

    const handleReportTypeChange = (value) => {
        setReportType(value);
    };

    const onSubmit = (data) => {
        if (reportType == "Machine Wise Report") {
            GetMachineWiseReport(data.pk_machineid).then(async (res) => {
                if (res.data.result !== null) {
                    setMachineWiseReport(res.data.result);
                }
            }).catch(function (error) {

            });
        }
        else {
            GetDailyReport(data.report_date).then(async (res) => {
                if (res.data.result !== null) {
                    setDailyReport(res.data.result);
                }
            }).catch(function (error) {

            });
        }
        setChosenReport(reportType);
    }

    return (
        <div className="card">
            <form onSubmit={handleSubmit(onSubmit)} className="p-fluid grid formgrid">
                <div className="field col-3">
                    <Dropdown placeholder='Please select type' id="report_type" value={reportType} onChange={(e) => handleReportTypeChange(e.value)}
                        options={reportTypes} />
                </div>
                {reportType == "Machine Wise Report" ? <div className="field col-3">
                    <Controller name="pk_machineid" control={control} render={({ field, fieldState }) => (
                        <Dropdown placeholder='Please select machine' id="pk_machineid" value={field.value} onChange={(e) => field.onChange(e.value)}
                            options={machinesList} className={classNames({ 'p-invalid': fieldState.invalid })} />
                    )} />
                </div> : null}
                {reportType == "Daily Report" ? <div className="field col-3">
                    <span className="p-float-label">
                        <Controller name="report_date" control={control} render={({ field, fieldState }) => (
                            <>
                                <Calendar inputId={field.name} value={field.value} onChange={field.onChange} dateFormat="dd/mm/yy" className={classNames({ 'p-invalid': fieldState.error })} />
                                <label htmlFor={field.name}>Report Date</label>
                                {getFormErrorMessage(field.name)}
                            </>
                        )} />
                    </span>
                </div> : null}
                <div className="field col-2">
                    <span className="p-float-label">
                        <Button type="submit" label="Submit" className="mt-2 md:col-2" />
                    </span>
                </div>
            </form>
            {chosenReport == "Machine Wise Report" ? <DataTable value={machineWiseReport} expandedRows={expandedRows} rowExpansionTemplate={rowExpansionTemplate}
                onRowToggle={(e) =>
                    setExpandedRows(e.data)
                } dataKey="id" showGridlines tableStyle={{ minWidth: '50rem' }} >
                <Column expander={allowExpansion} style={{ width: '5rem' }} />
                <Column field="department_Name" header="Department"></Column>
                <Column field="checklistDate" header="Date"></Column>
                <Column header="Checked" dataType="boolean" bodyClassName="text-center" style={{ minWidth: '8rem' }} body={checkedBodyTemplate} />
            </DataTable> : null}
            {chosenReport == "Daily Report" ? <DataTable value={dailyReport} expandedRows={expandedRows} rowExpansionTemplate={rowExpansionTemplate}
                onRowToggle={(e) =>
                    setExpandedRows(e.data)
                } dataKey="id" showGridlines tableStyle={{ minWidth: '50rem' }} >
                <Column expander={allowExpansion} style={{ width: '5rem' }} />
                <Column field="machine_Name" header="Machine Name"></Column>
                <Column field="supervisor_Name" header="Supervisor Name"></Column>
                <Column field="department_Name" header="Department"></Column>
                <Column header="Checked" dataType="boolean" bodyClassName="text-center" style={{ minWidth: '8rem' }} body={checkedBodyTemplate} />
            </DataTable> : null}
        </div>
    )
}

export default ReportList