import React, { useEffect, useState } from 'react'
import './sidebar.scss'
import { Link, useLocation } from 'react-router-dom'
import sidebarNav from '../../configs/sidebarNav'
import { useSelector } from 'react-redux'
import logoImg from '../../assets/images/logo.png'
import { useNavigate } from 'react-router-dom'

const Sidebar = () => {
    const navigate = useNavigate();
    const [activeIndex, setActiveIndex] = useState(0)
    const [selectedMenu, setSelectedMenu] = useState("");
    const location = useLocation()
    const stateUser = useSelector((state) => state.handleUser);

    useEffect(() => {
        const curPath = window.location.pathname.split('/')[1]
        const activeItem = sidebarNav.findIndex(item => item.section === curPath)
        setActiveIndex(curPath.length === 0 ? 0 : activeItem)
    }, [location])

    const closeSidebar = () => {
        document.querySelector('.main__content').style.transform = 'scale(1) translateX(0)'
        setTimeout(() => {
            document.body.classList.remove('sidebar-open')
            document.querySelector('.main__content').style = ''
        }, 500);
    }

    const MenuBarClick = (MenuName) => {
        if (selectedMenu !== MenuName)
            setSelectedMenu(MenuName);
        else
            setSelectedMenu("");
    }

    const menuAccess = (nav) => {
        if (stateUser.checkedMenus !== "") {
            if (stateUser.pkRoleId !== 0) {
                const obj = JSON.parse(stateUser.checkedMenus);
                if (obj[nav.key] !== undefined)
                    return true;
                else
                    return false;
            }
            else
                return true;
        }
        else
        navigate('/');
    }

    return (
        <div className='sidebar'>
            <div className="sidebar__logo">
                <img src={logoImg} alt="" style={{ height: "65px", width: "150px" }} />
                <div className="sidebar-close" onClick={closeSidebar}>
                    <i className='bx bx-x'></i>
                </div>
            </div>
            <div className="sidebar__menu">
                <div className="side-bar">
                    <div className="menu">
                        {
                            sidebarNav.map((nav, index) => (
                                menuAccess(nav) ? (nav.section !== "collapse" ?
                                    <div key={nav.key} className="item">
                                        <Link to={nav.link}>
                                            {nav.icon}{nav.label}
                                        </Link>
                                    </div>
                                    :
                                    <div key={nav.key} className={"item"}>
                                        <a className="sub-btn" onClick={() => MenuBarClick(nav.label)}>{nav.icon}{nav.label}</a>
                                        <div className={"sub-menu " + (selectedMenu === nav.label ? "" : "sub-menu-hide")}>
                                            {nav.children.map((navChild) => (
                                                menuAccess(navChild) ? <Link key={navChild.key} to={navChild.link}>
                                                    {navChild.label}
                                                </Link> : null
                                            ))}
                                        </div>
                                    </div>) : null
                            ))
                        }
                    </div>
                </div>
                <div className="sidebar__menu__item">
                    <div className="sidebar__menu__item__icon">
                        <i className='bx bx-log-out'></i>
                    </div>
                    <Link className='sidebar__menu__item__txt' to='/'>
                        Logout
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default Sidebar
