import images from "./images"

const data = {
    user: {
        name: 'Admin',
        img: images.avt
    },
    summary: [
        {
            title: 'Machines',
            subtitle: 'Total working machines',
            value: '250',
            percent: 100
        },
        {
            title: 'Machines Validated',
            subtitle: 'Checklist Approved Machines Total',
            value: '150',
            percent: 60
        },
        {
            title: 'Pending Approval',
            subtitle: 'Total machines pending for approval',
            value: '50',
            percent: 20
        },
        {
            title: 'Pending Checklist',
            subtitle: 'Total machines yet to be validated',
            value: '50',
            percent: 20
        }
    ],
    revenueSummary: {
        title: 'Revenue',
        value: '$678',
        chartData: {
            labels: ['May', 'Jun', 'July', 'Aug', 'May', 'Jun', 'July', 'Aug'],
            data: [300, 300, 280, 380, 200, 300, 280, 350]
        }
    },
    overall: [
        {
            value: '250',
            title: 'Total Machines'
        },
        {
            value: '100',
            title: 'Validated Machines'
        },
        {
            value: '40',
            title: 'Waiting for approval'
        },
        {
            value: '10',
            title: 'Pending Machines'
        }
    ],
    revenueByChannel: [
        {
            title: 'Direct',
            value: 70
        },
        {
            title: 'External search',
            value: 40
        },
        {
            title: 'Referal',
            value: 60
        },
        {
            title: 'Social',
            value: 30
        }
    ],
    revenueByMonths: {
        labels: ['10-June', '11-June', '12-June', '13-June', '14-June', '15-June', '16-June', '17-June', '18-June', '19-June', '20-June'],
        data: [250, 248, 250, 250, 250, 230, 240, 250, 250, 249, 250]
    }
}

export default data